import { notification } from "antd";

const pNotification = ({ type, message, description }) => {
  notification[type]({
    message: message || "Notification",
    description: description,
    style: {
      top: "72px"
    }
  });
};

export default pNotification;
