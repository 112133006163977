import { css } from "styled-components";

/* ====== COLORS ======= */
export const colorPalette = {
  prune: "#62192B",
  blackGreen: "#040707",
  blackGray1: "#1A1818",
  darkGray: "#655B52",
  darkGray1: "#534940",
  darkGray2: "#762738",
  classyRed: "#762738",
  darkGrayOpacity4: "#f9f9f8",
  lightGray: "#D6D5D5",
  lightGray1: "#F9F9F9",
  lightGray2: "#F0F0F0",
  lightGray3: "#EDEDED",
  lightBrown: "#D7BFAE",
  lightBrownOpacity20: "rgba(215, 191, 174, 0.2)",
  white: "#FFFFFF",
  black: "#000000",
  placeholder: "#bdbdbd",
  disabledBg: "#f5f5f5",
  shadow1: "#0A0C0C1A",
  shadow2: "#00000040",
  shadow3: "#00000065",
  shadow4: "#00000027",
  borderColor1: "#707070",
  focusColor: "#040707",
  negative: "#ef5350",
  amountPositive: "#388e3c",
  hoverColor1: "#04070760",
  hoverColor2: "#915c69", // ##00000065 opacity: 71%
};

/* ====== FONTS ======= */
export const fonts = {
  header: "'Lora'",
  subHeader: "'Montserrat'",
  body: "Open Sans",
};

/* ====== SIZE ======= */
export const spacings = {
  spacing_xxxs: "2px",
  spacing_xxs: "4px",
  spacing_xxss: "6px",
  spacing_xs: "8px",
  spacing_xss: "10px",
  spacing_s: "12px",
  spacing_sm: "14px",
  spacing_m: "16px",
  spacing_ml: "18px",
  spacing_l: "20px",
  spacing_l_m: "22px",
  spacing_xl: "24px",
  spacing_xxl: "28px",
  spacing_xxl_m: "30px",
  spacing_xxl_2: "32px",
  spacing_xxl_3: "36px",
  spacing_xxl_4: "38px",
  spacing_big: "60px",
};

/* ====== TEXT STYLES ======= */
export const textStyles = {
  btnDefault: css`
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: bold;
    color: ${colorPalette.blackGreen};
  `,
  btnPrimary: css`
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: bold;
    color: ${colorPalette.white};
  `,
  menuItem: css`
    font-family: "Montserrat Medium";
    font-size: 15px;
    font-weight: normal;
    color: ${colorPalette.blackGreen};
  `,
  sectionTitle: css`
    font-family: "Lora Medium";
    font-size: 32px;
    font-weight: normal;
    color: ${colorPalette.blackGreen};
    line-height: 32px;
  `,
  content1: css`
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: normal;
    color: ${colorPalette.blackGreen};
  `,
  content2: css`
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: normal;
    color: ${colorPalette.blackGreen};
  `,
  content3: css`
    font-family: "Open Sans";
    font-size: 16px;
    font-weight: normal;
    color: ${colorPalette.blackGreen};
  `,
  content4: css`
    font-family: "Montserrat";
    font-size: 15px;
    font-weight: normal;
    color: ${colorPalette.blackGreen};
  `,
  title1: css`
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
    color: ${colorPalette.blackGreen};
  `,
  title2: css`
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: bold;
    color: ${colorPalette.blackGreen};
  `,
  titleM: css`
    font-family: "Montserrat";
    font-size: 22px;
    font-weight: normal;
    color: ${colorPalette.prune};
  `,
  titleBold1: css`
    font-family: "Montserrat";
    font-size: 20px;
    font-weight: bold;
    color: ${colorPalette.blackGreen};
  `,
  titleSmall1: css`
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    color: ${colorPalette.blackGreen};
  `,
  titleSmall2: css`
    font-family: "Montserrat";
    font-size: 18px;
    font-weight: bold;
    color: ${colorPalette.blackGreen};
    letter-spacing: 1px;
  `,
  inputElementValueDefault: css`
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: bold;
    color: ${colorPalette.blackGreen};
  `,
  formElementLabelDefault: css`
    font-family: "Montserrat SemiBold";
    font-size: 12px;
    font-weight: normal;
    color: ${colorPalette.blackGreen};
  `,
};

/* ====== LAYOUT STYLES ======= */
export const layout = {
  header: {
    height: 106,
    heightiPad: 86,
    heightMobile: 51,
  },
  content: {
    maxWidth: 1500,
    paddingHorizontal: {
      desktop: 60,
      ipad: 30,
      mobile: 18,
    },
  },
  footer: {},
};
