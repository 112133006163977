import {
  colorPalette,
  fonts,
  textStyles,
  spacings,
  layout,
} from "./styleguide";

const defaultTheme = {
  colors: colorPalette,
  fonts: fonts,
  spacings: spacings,
  layout: layout,
  textStyles: textStyles,
  components: {
    button: {
      default: {
        text: textStyles.btnDefault,
        bgColor: colorPalette.white,
        hoverBgColor: colorPalette.blackGreen,
        color: colorPalette.classyRed,
        hoverColor: colorPalette.white,
        shadowColor: colorPalette.shadow3,
        disabledBgColor: colorPalette.disabledBg,
      },
      primary: {
        text: textStyles.btnPrimary,
        bgColor: colorPalette.blackGreen,
        hoverBgColor: colorPalette.hoverColor1,
        color: colorPalette.white,
        hoverColor: colorPalette.white,
        shadowColor: colorPalette.shadow3,
        disabledBgColor: colorPalette.disabledBg,
      },
      primaryVariant: {
        text: textStyles.btnPrimary,
        bgColor: colorPalette.darkGray2,
        hoverBgColor: colorPalette.blackGray1,
        color: colorPalette.white,
        hoverColor: colorPalette.white,
        shadowColor: colorPalette.shadow3,
        disabledBgColor: colorPalette.disabledBg,
      },
    },
    menuItem: {
      text: textStyles.menuItem,
    },
    cards: {
      serviceCard: {
        title: textStyles.titleBold1,
        content: textStyles.content3,
      },
    },
  },
};

export default {
  default: defaultTheme,
};
