import { useState, useEffect } from "react";

function getWindowDimensions() {
  if (typeof window !== "undefined") {
    // detect window screen width function
    const { innerWidth: width, innerHeight: height } = window;
    const isDesktop = width > 768;
    const isPad = 560 < width && width < 769;
    const isMobile = width < 560;
    return {
      width,
      height,
      isDesktop,
      isPad,
      isMobile,
      isSmallDevice: isMobile || isPad,
    };
  } else {
    return {
      width: 0,
      height: 0,
      isDesktop: true,
      isPad: false,
      isMobile: false,
      isSmallDevice: false,
    };
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
