import React, { useEffect, useState } from "react";
import Router, { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "styled-components";
import TagManager from "react-gtm-module";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { config } from '@fortawesome/fontawesome-svg-core'
import '@fortawesome/fontawesome-svg-core/styles.css'
config.autoAddCss = false;
// import { useExperiment } from "../hooks/useExperiment";
const SchedulePopup = dynamic(() => import("../containers/SchedulePopup"));
import { useExitIntent } from 'use-exit-intent';
const ExitPopup = dynamic(() => import("../components/ExitPopup"));
// import { handleExitIntentPopup } from "../utils/exit-popup";

import "antd/dist/antd.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/index.css";
import "../public/fonts/fonts.css";
import themes from "../constants/theme";

import * as gtag from "../utils/gtag";
import { wrapper } from "../redux/store";
import { ScrollProvider } from "../context/ScrollProvider";
import { HeaderProvider } from "../context/HeaderProvider";
import Script from "next/script";
const Head = dynamic(() => import("next/head"));
import useQueryCatcher from "../hooks/useQueryCatcher";
// import Modal20OFF from "../containers/Modal20Popup";

const LayoutContainer = dynamic(() => import("../components/LayoutContainer"));

// Progress Bar configuration.
NProgress.configure({ easing: "ease", speed: 500, showSpinner: false });
// Binding events.
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const tagManagerArgs = {
  gtmId: gtag.GA_TRACKING_ID,
};

function App({ Component, pageProps }) {
  const router = useRouter();
  useQueryCatcher(router);
  const [showModal, setShowModal] = useState(false);
  // const variant = useExperiment(process.env.NEXT_PUBLIC_EXPERIMENT_ID);
  // const { registerHandler, unsubscribe } = useExitIntent({
  //   cookie: {
  //     key: 'exit-intent',
  //     daysToExpire: 7,
  //   }
  // });
  // registerHandler({
  //   id: 'exit-modal',
  //   handler: () => handleExitIntentPopup(setShowModal),
  // });
  // const handleCloseModal = () => {
  //   document.body.style.overflow = "unset";
  //   unsubscribe();
  // };
  useEffect(() => {
    setTimeout(() => {
      TagManager.initialize(tagManagerArgs);
      let visitorIdInerval = setInterval(() => {
        if (window.uniqueVisitorId) {
          TagManager.dataLayer({
            dataLayer: {
              visitor_id: window.uniqueVisitorId,
              confidenceScore: window.confidenceScore,
            },
          });
          clearInterval(visitorIdInerval);
        }
      }, 1000);
    }, 2000);
  }, []);

  return (
    <>
      <Head>
        <meta name="p:domain_verify" content="8937654354c60370a35016267bb9f74b" />
      </Head>
      <Script
        src="https://www.googleoptimize.com/optimize.js?id=OPT-P7QXH6P"
        strategy="afterInteractive"
      />
      <Script
        id="fingerprint-load"
      >
        {`const fpPromise = import('https://fpjscdn.net/v3/gnfeo8PczFa0G2uUBIEB')
            .then(FingerprintJS => FingerprintJS.load());
          fpPromise
            .then(fp => fp.get())
            .then(result => {
              window.uniqueVisitorId = result?.visitorId;
              window.confidenceScore = result?.confidence.score;
            })
            .catch(error => console.error(error));
            `}
      </Script>
      {/* <FpjsProvider
        loadOptions={{
          apiKey: 'yIvte5DVBN6pRFbj5RPB',
        }}
      > */}
      <CookiesProvider>
        <LayoutContainer>
          <ThemeProvider theme={themes.default}>
            <ScrollProvider>
              <HeaderProvider>
                <Component {...pageProps} />
                {/* <ExitPopup unsubscribe={handleCloseModal} showModal={showModal} setShowModal={setShowModal} /> */}
              </HeaderProvider>
            </ScrollProvider>
            <SchedulePopup />
            {/* <BrochurePopup /> */}
          </ThemeProvider>
        </LayoutContainer>
      </CookiesProvider>
      {/* </FpjsProvider> */}
    </>
  );
}

export default wrapper.withRedux(App);
